import types from '../types.js';
import { setState } from '../../utils/redux';

const initialState = {
  sites: [],
  selectedSite: null
};

const siteReducer = (state = initialState, action) => {
  const { LOAD_SITES, SELECT_SITE } = types;

  switch (action.type) {
    case LOAD_SITES:
      return setState(state, 'sites', action.payload);
    case SELECT_SITE:
      return setState(state, 'selectedSite', action.payload);
    default:
      return state;
  }
};

export default siteReducer;
