import { takeLatest } from 'redux-saga/effects';
import types from '../redux/types.js';
import { loadProjects, updateUserDefaultProject, fetchTables } from './project.js';
import { fetchTemplates } from './template.js';
import { updateLocalStore } from './user.js';
import { fetchNotificationPreferences, fetchNotifications, fetchNotificationTypes } from './notification.js';
import { dismissAlert, finishLoading } from './dashboard.js';
import { fetchIntegrations } from './integeration.js';
import { fetchSites } from './site.js';

function* sideFX() {
  yield takeLatest(types.SET_USER, fetchIntegrations);
  yield takeLatest(types.SET_USER, loadProjects);
  yield takeLatest(types.SELECT_PROJECT, updateUserDefaultProject);
  yield takeLatest(types.SELECT_PROJECT, fetchTemplates);
  yield takeLatest(types.SELECT_PROJECT, fetchTables);
  yield takeLatest(types.SET_PROJECT_TABLES, finishLoading);
  yield takeLatest(types.SET_ALERT, dismissAlert);
  yield takeLatest(types.UPDATE_USER, fetchNotifications);
  yield takeLatest(types.UPDATE_USER, fetchNotificationTypes);
  yield takeLatest(types.LOAD_NOTIFICATION_TYPES, fetchNotificationPreferences);
  yield takeLatest(types.UPDATE_USER, updateLocalStore);
  yield takeLatest(types.SELECT_PROJECT, fetchSites);
}

export default sideFX;
