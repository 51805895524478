// Objects
import summary from './summary.svg';
import dashboardDark from './dashboard-dark.svg';
import analytics from './analytics.svg';
import analyticsDark from './analytics-dark.svg';
import charts from './charts.svg';
import chartsOutline from './charts-outline.svg';
import compare from './compare.svg';
import insights from './insights.svg';
import compareDark from './compare-dark.svg';
import integrations from './integrations.svg';
import preset from './preset.svg';
import device from './device.svg';
import deviceWhite from './device-white.svg';
import deviceOutline from './device-outline.svg';
import deviceOutlineDark from './device-outline-dark.svg';
import calendar from './calendar.svg';
import report from './report-white.svg';
import reportDark from './report.svg';
import reportOutlineDark from './report-outline-dark.svg';
import reportEmail from './report-email.svg';
import reportRecurring from './report-recurring.svg';
import settings from './settings.svg';
import settingsDark from './settings-dark.svg';
import advancedSettings from './advanced-settings.svg';
import advancedSettingsDark from './advanced-settings-dark.svg';
import user from './user.svg';
import userDark from './user-dark.svg';
import userCircleDark from './user-circle-dark.svg';
import integrationDark from './integration-dark.svg';
import logoutDark from './logout-dark.svg';
import externalLinkDark from './external-link-dark.svg';
import security from './security.svg';
import folder from './folder.svg';
import copy from './copy.svg';
import search from './search.png';
import searchDark from './search-dark.svg';
import stop from './stop.svg';
import play from './play.svg';
import reload from './reload.svg';
import del from './delete.svg';
import trash from './trash.svg';
import pen from './pen.svg';
import download from './download.svg';
import project from './project.svg';
import projectOutline from './project-dark-outline.svg';
import projectDarkOutline from './project-dark-outline.svg';
import notificationDarkOutline from './notification-dark-outline.svg';
import unreadNotificationDarkOutline from './unread-notification-dark-outline.svg';
import transfer from './transfer.svg';
import transferBlue from './transfer-blue.svg';
import info from './info.svg';
import timezone from './timezone.svg';
import check from './check.svg';
import checked from './checked.svg';
import unchecked from './unchecked.svg';
import addUser from './add-user.svg';
import removeUser from './remove-user.svg';
import reloadDarkOutline from './reload-dark-outline.svg';
import message from './message.svg';
import archive from './archive.svg';
import male from './male.svg';
import female from './female.svg';
import growthFalling from './growth-falling.svg';
import growthDecreasing from './growth-decreasing.svg';
import growthNeutral from './growth-neutral.svg';
import growthRising from './growth-rising.svg';
import growthGood from './growth-good.svg';
import media from './media.svg';
import connection from './connection.svg';
import more from './more.svg';
import filter from './filter.svg';
import grid from './grid.svg';
import list from './list.svg';
import projectArrow from './project-arrow.svg';
import windows from './windows.svg';
import linux from './linux.svg';
import balena from './balena.svg';
import brightsign from './brightsign.svg';
import unknown from './unknown.svg';
import location from './location.svg';
import imageThumbnail from './image.svg';
import navigate from './navigate.svg';
import pin from './pin.svg';

// Arrows
import angleUp from './angle-up.svg';
import angleRight from './angle-right.svg';
import angleDown from './angle-down.svg';
import angleLeft from './angle-left.svg';
import recurrency from './recurrency.svg';

// Status
import online from './online.svg';
import offline from './offline.svg';
import paused from './paused.svg';
import stopped from './stopped.svg';

// Solutions
import audience from './audience.svg';
import crowd from './crowd.svg';
import vehicleDetection from './vehicle-detection.svg';
import vehicleRecognition from './vehicle-recognition.svg';
import vehicleCrowd from './vehicle-crowd.svg';
import cms from './cms.png';
import customSolution from './custom-solution.svg';

// Authentication
import eyeDark from './eye-dark.svg';
import eyeSlashDark from './eye-slash-dark.svg';

// Multiselector
import moveAllUp from './move-all-up.png';
import moveAllDown from './move-all-down.png';
import moveSelUp from './move-sel-up.png';
import moveSelDown from './move-sel-down.png';
import soloDark from './solo-dark.svg';

// Notification icons
import noNotifications from './no-notifications.svg';
import deviceOutlineBlue from './device_outline_blue.svg';
import deviceOutlineGreen from './device_outline_green.svg';
import deviceOutlineRed from './device_outline_red.svg';
import deviceOutlineYellow from './device_outline_yellow.svg';
import analyticsOutlineBlue from './analytics_outline_blue.svg';
import analyticsOutlineGreen from './analytics_outline_green.svg';
import analyticsOutlineRed from './analytics_outline_red.svg';
import analyticsOutlineYellow from './analytics_outline_yellow.svg';
import integrationOutlineBlue from './integration_outline_blue.svg';
import integrationOutlineGreen from './integration_outline_green.svg';
import integrationOutlineRed from './integration_outline_red.svg';
import integrationOutlineYellow from './integration_outline_yellow.svg';
import managementOutlineBlue from './management_outline_blue.svg';
import managementOutlineGreen from './management_outline_green.svg';
import managementOutlineRed from './management_outline_red.svg';
import managementOutlineYellow from './management_outline_yellow.svg';
import projectOutlineBlue from './project_outline_blue.svg';
import projectOutlineGreen from './project_outline_green.svg';
import projectOutlineRed from './project_outline_red.svg';
import projectOutlineYellow from './project_outline_yellow.svg';
import reportOutlineBlue from './report_outline_blue.svg';
import reportOutlineGreen from './report_outline_green.svg';
import reportOutlineRed from './report_outline_red.svg';
import reportOutlineYellow from './report_outline_yellow.svg';
import standardOutlineBlue from './standard_outline_blue.svg';
import standardOutlineGreen from './standard_outline_green.svg';
import standardOutlineRed from './standard_outline_red.svg';
import standardOutlineYellow from './standard_outline_yellow.svg';
import close from './close.svg';
import closeError from './close-error.svg';
import closePrimary from './close-primary.svg';
import closeSuccess from './close-success.svg';
import closeWarning from './close-warning.svg';
import leave from './leave.svg';
import impersonateEye from './impersonate-eye.svg';
import impersonateChange from './impersonate-change.svg';
import key from './key.svg';

// Campaigns
import campaigns from './campaigns.svg';
import discovery from './discovery.svg';
import portal from './portal.svg';

// Commands
import cmdStart from './cmd-start.svg';
import cmdStop from './cmd-stop.svg';
import cmdRestart from './cmd-restart.svg';
import cmdPreview from './cmd-preview.svg';
import cmdUpdate from './cmd-update.svg';

export default {
  // Solutions
  FACE: audience,
  FACEV2: audience,
  CROWD: crowd,
  CROWDV3: crowd,
  CROWDV2: crowd,
  VEHICLE: vehicleDetection,
  VEHICLES: vehicleDetection,
  VEHICLERECOGNITION: vehicleRecognition,
  VEHICLEDETECTIONV1: vehicleDetection,
  VEHICLERECOGNITIONV1: vehicleRecognition,
  VEHICLECROWD: vehicleCrowd,
  CUSTOM: customSolution,
  AUDIENCE: crowd,
  TRAFFIC: vehicleRecognition,

  // CMS
  SIGNAGELIVE: cms,
  AYUDA: cms,
  BROADSIGN: cms,
  BRIGHTAUTHOR: cms,
  BROADSIGNVEHICLE: cms,
  SCALA: cms,

  // CMS - Alias for [solution] Vx
  SIGNAGELIVEFACEV1: cms,
  AYUDAFACEV1: cms,
  BROADSIGNFACEV1: cms,
  BRIGHTAUTHORFACEV1: cms,
  BROADSIGNVEHICLEV1: cms,
  SCALAFACEV0: cms,

  // Objects
  dashboardDark,
  summary,
  analytics,
  analyticsDark,
  charts,
  chartsOutline,
  compare,
  insights,
  compareDark,
  integrations,
  preset,
  device,
  deviceWhite,
  deviceOutline,
  deviceOutlineDark,
  calendar,
  report,
  reportDark,
  reportOutlineDark,
  reportEmail,
  reportRecurring,
  settings,
  settingsDark,
  advancedSettings,
  advancedSettingsDark,
  user,
  userDark,
  userCircleDark,
  integrationDark,
  logoutDark,
  externalLinkDark,
  security,
  folder,
  copy,
  search,
  searchDark,
  stop,
  play,
  reload,
  del,
  trash,
  download,
  project,
  projectOutline,
  projectDarkOutline,
  notificationDarkOutline,
  unreadNotificationDarkOutline,
  reloadDarkOutline,
  transfer,
  transferBlue,
  info,
  timezone,
  check,
  checked,
  unchecked,
  addUser,
  removeUser,
  archive,
  message,
  male,
  female,
  growthFalling,
  growthDecreasing,
  growthNeutral,
  growthRising,
  growthGood,
  leave,
  impersonateEye,
  impersonateChange,
  media,
  connection,
  more,
  grid,
  list,
  projectArrow,
  windows,
  linux,
  balena,
  brightsign,
  unknown,
  pen,
  location,
  imageThumbnail,
  navigate,
  pin,

  // Arrows
  angleUp,
  angleRight,
  angleDown,
  angleLeft,
  recurrency,

  // Status
  online,
  offline,
  paused,
  stopped,

  // Authentication
  eyeDark,
  eyeSlashDark,

  // Multiselector
  moveAllUp,
  moveAllDown,
  moveSelUp,
  moveSelDown,
  soloDark,

  // Notification icons
  noNotifications,
  device_default: deviceOutlineBlue,
  device_success: deviceOutlineGreen,
  device_warn: deviceOutlineYellow,
  device_error: deviceOutlineRed,
  analytics_default: analyticsOutlineBlue,
  analytics_success: analyticsOutlineGreen,
  analytics_warn: analyticsOutlineYellow,
  analytics_error: analyticsOutlineRed,
  integration_default: integrationOutlineBlue,
  integration_success: integrationOutlineGreen,
  integration_warn: integrationOutlineYellow,
  integration_error: integrationOutlineRed,
  management_default: managementOutlineBlue,
  management_success: managementOutlineGreen,
  management_warn: managementOutlineYellow,
  management_error: managementOutlineRed,
  projects_default: projectOutlineBlue,
  projects_success: projectOutlineGreen,
  projects_warn: projectOutlineYellow,
  projects_error: projectOutlineRed,
  report_default: reportOutlineBlue,
  report_success: reportOutlineGreen,
  report_warn: reportOutlineYellow,
  report_error: reportOutlineRed,
  standard_default: standardOutlineBlue,
  standard_success: standardOutlineGreen,
  standard_warn: standardOutlineYellow,
  standard_error: standardOutlineRed,
  close,
  closeError,
  closeWarning,
  closeSuccess,
  closePrimary,
  closeInfo: closePrimary,
  key,
  filter,

  //campaings
  campaigns,
  discovery,
  portal,

  // Commands
  cmdStart,
  cmdStop,
  cmdRestart,
  cmdUpdate,
  cmdPreview
};
