import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import components from '../../../components';
import { AddUserModal, RemoveUserModal, InviteUserModal, UserPermissionModal } from './userModals';
import types from '../../../redux/types';
import { getUser } from '../../../redux/user/actions';

import { getRoleName } from '../../../utils/user/permissions';
import { bindMemberToProject, getMembers, updatePolicyPermissions } from '../../../utils/project';
import utils from '../../../utils';
import API from '../../../services/api';
import './styles.scss';
import { getSelectedCompany } from '../../../redux/company/actions';

const { CustomCheckBox, CustomButton, SearchBox, IconButton } = components;

const Users = ({ project, updateCounters }) => {
  const [users, setUsers] = useState([]);
  const [owner, setOwner] = useState({});
  const [loading, setLoading] = useState(true);
  const [isDataViewer, setIsDataViewer] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [modifiedUsers, setModifiedUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [currentModal, setCurrentModal] = useState('addUser');

  const currentUser = useSelector(getUser);
  const company = useSelector(getSelectedCompany);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    if (isMounted && project) {
      // Fetch policy to get user members
      fetchPolicy();
    }
    return () => {
      isMounted = false;
    };
  }, [project, newUsers]);

  const fetchPolicy = () => {
    if (project.id) {
      setLoading(true);
      API.getPolicy(project.companyId, project.id)
        .then(policy => {
          const members = [];
          // const members = getMembers(policy);
          policy.bindings.forEach(binding => {
            const role = binding.role;
            const roleMembers = [];
            binding.members.forEach(member => {
              const user = { email: member.replace('user:', ''), role };
              roleMembers.push(user);
            });
            members.push(...roleMembers);
          });

          // If the only user on this project is the owner
          if (members.length === 1) {
            setUsers([]);
            setSearchedUsers([]);
            setSelectedUsers([]);
            updateCounters('userCounter', 0);
          } else {
            updateUsersData(members);
          }
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  };

  const updateUsersData = members => {
    const emails = members.map(user => user.email);
    const users = [];

    if (emails.length > 0) {
      API.getUsersBatch(emails)
        .then(res => {
          res.users.forEach(user => {
            const foundUser = members.find(member => member.email === user.email);
            if (user.id === project.ownerId) {
              setOwner({
                ...user,
                id: user.id,
                roleName: getRoleName(foundUser.role),
                role: foundUser.role
              });
              return;
            }
            if (!foundUser) return;
            if (foundUser) {
              users.push({
                ...user,
                id: user.id,
                roleName: getRoleName(foundUser.role),
                role: foundUser.role
              });
            }

            setUsers(users);
            setSearchedUsers(users);
            setSelectedUsers([]);
            updateCounters('userCounter', users.length);
          });
        })
        .catch(console.error);
    }
  };

  const toggleSelection = (user, remove) => {
    if (remove) {
      setSelectedUsers([...selectedUsers.filter(curr => curr.id !== user.id)]);
    } else {
      selectedUsers.push(user);
      setSelectedUsers([...selectedUsers]);
    }
  };

  const selectAll = value => {
    // Select all users
    if (value) {
      setSelectedUsers(users);
    } else {
      // Deselect all users
      setSelectedUsers([]);
    }
  };

  const onSearch = searchBy => {
    if (searchBy !== '') {
      const searchedUsers = users.filter(user => {
        const userHas = option => user[option].toUpperCase().includes(searchBy.toUpperCase());
        return userHas('displayName') || userHas('id');
      });
      setSearchedUsers(searchedUsers);
    } else {
      setSearchedUsers(users);
    }
  };

  const isSelected = userId => {
    return selectedUsers.some(user => user.id === userId);
  };

  const addNewUsers = payload => {
    setIsDataViewer(payload.isDataViewer);
    setNewUsers(payload.users);
  };

  const showAddUserModal = () => {
    const isAdmin = currentUser.admin;

    if (isAdmin) {
      setCurrentModal('addUser');
      toggle();
    } else {
      dispatch({
        type: types.SET_ALERT,
        payload: utils.generateAlert(`Can't perform this action. Please, contact support.`, 'error')
      });
    }
  };

  const showInviteUserModal = () => {
    setCurrentModal('inviteUser');
    toggle();
  };

  const removeUsers = usersToBeRemoved => {
    const userIds = usersToBeRemoved.map(user => user.id);
    const remainingUsers = users.filter(modifiedUser => !userIds.includes(modifiedUser.id));
    remainingUsers.push(owner);

    const queryObject = {
      bindings: []
    };

    const roleGroups = remainingUsers.reduce((acc, user) => {
      if (!acc[user.role]) {
        acc[user.role] = [];
      }
      acc[user.role].push(`user:${user.email}`);
      return acc;
    }, {});

    for (const [role, emails] of Object.entries(roleGroups)) {
      queryObject.bindings.push({
        members: emails,
        role: role
      });
    }

    API.setPolicy(company.id, project.id, queryObject)
      .then(() => {
        fetchPolicy();
      })
      .catch(console.error);
  };

  const updateSelectedUserRole = (id, role) => {
    const newUsers = modifiedUsers.map(modifiedUser =>
      modifiedUser.id === id ? { ...modifiedUser, role, roleName: getRoleName(role) } : modifiedUser
    );

    setModifiedUsers([...newUsers]);
  };

  const updatePermissions = usersToModify => {
    const userKeys = usersToModify.map(userToModify => userToModify.id);

    // Filter out users that were selected but not modified
    let filteredUsers = modifiedUsers.filter(modifiedUser => userKeys.some(key => key === modifiedUser.id));
    const localUsers = users.filter(user => !userKeys.some(key => key === user.id));

    const allUsers = [...filteredUsers, ...localUsers];
    allUsers.push(owner);

    const queryObject = {
      bindings: []
    };

    const roleGroups = allUsers.reduce((acc, user) => {
      if (!acc[user.role]) {
        acc[user.role] = [];
      }
      acc[user.role].push(`user:${user.email}`);
      return acc;
    }, {});

    for (const [role, emails] of Object.entries(roleGroups)) {
      queryObject.bindings.push({
        members: emails,
        role: role
      });
    }

    API.setPolicy(company.id, project.id, queryObject)
      .then(() => {
        fetchPolicy();
      })
      .catch(console.error);
  };

  const bindUsers = () => {
    const resource = `projects/${project.id}`;
    API.getPolicy(resource)
      .then(policy => {
        const role = isDataViewer ? 'admobilize.dashboardDataViewer' : 'admobilize.dashboardUser';

        const newPolicy = bindMemberToProject(policy, newUsers, role);

        const body = {
          parent_name: 'organizations/admobilize',
          policy: newPolicy,
          resource
        };

        API.setPolicy(resource, body)
          .then(() => {
            const members = getMembers(newPolicy);
            updateUsersData(members);
          })
          .catch(console.error);
      })
      .catch(console.error)
      .finally(() => {
        // Always toggle new users modal
        toggle();
        // Clear new user list and force drawer update
        setNewUsers([]);
        setIsDataViewer(false);
      });
  };

  const toggle = () => {
    setModalOpen(!modalOpen);
  };

  const renderModal = () => {
    const modals = {
      managePermissions: (
        <UserPermissionModal
          toggle={toggle}
          modalOpen={modalOpen}
          selectedUsers={modifiedUsers}
          updateSelectedUserRole={updateSelectedUserRole}
          updatePermissions={updatePermissions}
        />
      ),
      removeUsers: (
        <RemoveUserModal
          toggle={toggle}
          modalOpen={modalOpen}
          project={project}
          removeUsers={removeUsers}
          selectedUsers={selectedUsers}
        />
      ),
      addUser: (
        <AddUserModal
          toggle={toggle}
          modalOpen={modalOpen}
          project={project}
          newUsers={newUsers}
          addNewUsers={addNewUsers}
          bindUsers={bindUsers}
        />
      ),
      inviteUser: <InviteUserModal toggle={toggle} modalOpen={modalOpen} project={project} />
    };

    return modals[currentModal];
  };

  const renderUser = user => {
    return (
      <div className="user-data-row">
        <label className="user-name">
          {user.displayName ? user.displayName.toUpperCase() : null}
          <span className="user-role">({user.roleName})</span>
        </label>
        <label className="user-email">{user.email}</label>
      </div>
    );
  };

  const renderAddUserButton = () => {
    const userFound = users.find(foundUser => foundUser.id === currentUser.id);

    // Do not render Add User button for dataViewer users
    if (userFound && userFound.role === 'admobilize.viewer') {
      return null;
    }

    return (
      <div className="add-invite-buttons">
        {/* {currentUser.admin && (
          <CustomButton title="+ Add User" classes={'btn-add-user btn-success'} handleClick={showAddUserModal} />
        )} */}
        <CustomButton title="Invite User" classes={'btn-add-user btn-primary'} handleClick={showInviteUserModal} />
      </div>
    );
  };

  const renderToolbar = () => (
    <>
      <div className="search-container">
        <SearchBox onChange={onSearch} placeholder={'Search User'} />
        <div>{renderAddUserButton()}</div>
      </div>
      <div className="toolbar">
        <CustomCheckBox
          multiple
          selectedItems={selectedUsers.length}
          totalItems={users.length}
          label={'All'}
          onClick={selectAll}
        />
        <div className="bulk-action-container">
          <IconButton
            id={'btn-permission-user'}
            btnClasses={'outline'}
            tooltip={'User Roles'}
            icon={'key'}
            disabled={selectedUsers.length === 0}
            onPress={() => {
              setModifiedUsers([...selectedUsers]);
              setCurrentModal('managePermissions');
              toggle();
            }}
          />
          <IconButton
            id={'btn-remove-user'}
            btnClasses={'outline'}
            tooltip={'Remove User'}
            icon={'removeUser'}
            disabled={selectedUsers.length === 0}
            onPress={() => {
              setCurrentModal('removeUsers');
              toggle();
            }}
          />
        </div>
      </div>
    </>
  );

  const renderUserList = () => {
    return searchedUsers.map(searchedUser => {
      return (
        <div className="user-row" key={searchedUser.id}>
          <CustomCheckBox
            selected={isSelected(searchedUser.id) ? 'all' : 'none'}
            label={renderUser(searchedUser)}
            onClick={value => toggleSelection(searchedUser, value)}
          />
          <div className="quick-action-buttons">
            <IconButton
              id={`permission-user-${searchedUser.id}`}
              btnClasses={'outline'}
              icon={'key'}
              onPress={() => {
                setModifiedUsers([searchedUser]);
                setCurrentModal('managePermissions');
                toggle();
              }}
              tooltip={'Change user role'}
            />
            <IconButton
              id={`remove-user-${searchedUser.id}`}
              btnClasses={'outline'}
              icon={'removeUser'}
              onPress={() => {
                setSelectedUsers([searchedUser]);
                setCurrentModal('removeUsers');
                toggle();
              }}
              tooltip={'Remove this user'}
            />
          </div>
        </div>
      );
    });
  };

  const renderContent = () => (
    <div className="filter-list flex-fill user-section">
      {renderModal()}
      {users.length === 0 ? (
        <div className="no-users">
          <p>No users beside the owner</p>
          {renderAddUserButton()}
        </div>
      ) : (
        <div className="user-list">
          {renderToolbar()}
          {renderUserList()}
        </div>
      )}
    </div>
  );

  // RENDER
  return loading ? <div>Loading...</div> : renderContent();
};

export default Users;
