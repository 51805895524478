import { call, put, select } from 'redux-saga/effects';
import types from '../redux/types';
import API from '../services/api';

export function* fetchSites() {
  try {
    const company = yield select(state => state.companyReducer.selectedCompany);
    const project = yield select(state => state.projectReducer.selectedProject);

    if (company && project) {
      const sites = yield call(API.getSites, company.id, project.id);

      if (sites) {
        yield put({ type: types.LOAD_SITES, payload: sites.sites });
        yield put({ type: types.SELECT_SITE, payload: sites.sites[0] });
      }
    }
  } catch (error) {
    console.error("Couldn't fetch sites", error);
  }
}
