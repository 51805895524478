import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import IconButton from '../iconButton';
import SearchBox from '../searchBox';
import CustomButton from '../customButton';
import { setAlert, setLoading } from '../../redux/dashboard/actions';
import { setUser } from '../../redux/user/actions';
import icons from '../../assets/icons';
import API from '../../services/api';
import utils from '../../utils';
import './styles.scss';

const ImpersonateFrame = ({ enabled, user, stopImpersonating, impersonateModalOpened, setImpersonateModalOpened }) => {
  const [searched, setSearched] = useState('');
  const [tryingToImpersonate, setTryingToImpersonate] = useState(false);

  const keydownListener = useCallback(keydownEvent => {
    const { key, repeat } = keydownEvent;
    if (!impersonateModalOpened) return;
    if (repeat) return;
    if (!key) return;

    if (key.toLowerCase() === 'enter') {
      impersonateUser();
    }

    if (key.toLowerCase() === 'escape') {
      setImpersonateModalOpened(false);
    }
  });

  useEffect(() => {
    window.addEventListener('keydown', keydownListener, true);
    return () => window.removeEventListener('keydown', keydownListener, true);
  }, [keydownListener]);

  const dispatch = useDispatch();

  const displayName = user ? user.name : '';

  const impersonateUser = () => {
    // Return if the search input is empty
    if (searched === '') return;

    // Use impersonator token if there is one, otherwise use token from current user
    const token = enabled ? user.impersonator.accessToken : user.accessToken;

    setTryingToImpersonate(true);

    // Needs to get user ID before calling impersonate endpoint
    API.getUsersBatch([searched])
      .then(userFound => {
        const userObject = userFound?.users[0];
        API.impersonateUser(userObject.id, token)
          .then(newUser => {
            // Update accessToken on header Authorization
            API.setAccessToken(newUser?.token?.accessToken);

            const impersonatedUser = {
              ...userObject,
              name: userObject.displayName,
              accessToken: newUser?.token?.accessToken,
              id: userObject.id,
              impersonator: enabled ? user.impersonator : user, // Keep impersonator or add one if there is none
              permissions: {}
            };

            localStorage.setItem('userImpersonated', JSON.stringify(impersonatedUser));

            // Update User reference on Redux
            dispatch(setUser(impersonatedUser));
            const alert = utils.generateAlert(`You are now impersonating ${userObject.email}`, 'success');
            dispatch(setLoading(true));
            setTryingToImpersonate(false);
            dispatch(setAlert(alert));
            setImpersonateModalOpened(false);
          })
          .catch(err => {
            console.error(err);
            const alert = utils.generateAlert('Impersonate was not possible.', 'error');
            dispatch(setAlert(alert));
            setTryingToImpersonate(false);
          });
      })
      .catch(err => {
        const alert = utils.generateAlert('User not found.', 'error');
        dispatch(setAlert(alert));
        console.error(err);
        setTryingToImpersonate(false);
      });
  };

  return (
    <div className="impersonate-mode">
      {enabled && (
        <div>
          <div className="imp-border top"></div>
          <div className="imp-border right"></div>
          <div className="imp-border bottom"></div>
          <div className="imp-border left"></div>

          <div className="imp-tools">
            <img className="eyes-icon" src={icons.impersonateEye} alt="eye" />
            <span className="impersonating-name">You are impersonating {displayName}</span>
            <IconButton
              id="change-user"
              onPress={() => setImpersonateModalOpened(true)}
              tooltip="Change user"
              icon="impersonateChange"
            />
            <IconButton
              id="leave-impersonation"
              onPress={stopImpersonating}
              tooltip="Leave impersonation"
              icon="leave"
            />
          </div>
        </div>
      )}
      <Modal className="impersonate-modal" isOpen={impersonateModalOpened} fade={false} centered>
        <ModalBody>
          <div className="impersonate-modal-body">
            <div className="close-btn-container">
              <IconButton id="impersonate-close-btn" onPress={() => setImpersonateModalOpened(false)} icon="close" />
            </div>
            <div className="content-wrapper">
              <h2>Impersonate new user</h2>
              <p>{`See dashboard data as impersonated user`}</p>

              <SearchBox autoFocus onChange={setSearched} placeholder={'Search Email or ID'} />
              <div className="flex-fill justify-content-end">
                <div className="modal-buttons d-flex">
                  <CustomButton
                    title={'Cancel'}
                    classes={'btn-secondary'}
                    handleClick={() => setImpersonateModalOpened(false)}
                  />
                  <CustomButton
                    title={'Impersonate'}
                    classes={'btn-primary'}
                    disabled={searched === ''}
                    loading={tryingToImpersonate}
                    handleClick={impersonateUser}
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ImpersonateFrame;
