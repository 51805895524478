import insightsTableHeaders from './insightsTableHeaders';
import insightsTableData from './insightsTableData';

// const solutionsMeta = [
//   {
//     id: 'facev2',
//     name: 'Audience',
//     table: 'facev2',
//     type: 'facev2',
//     cms: false
//   },
//   {
//     id: 'crowdv2',
//     name: 'Crowd V2',
//     table: 'crowdv2',
//     type: 'crowdv2',
//     cms: false
//   },
//   {
//     id: 'crowdv3',
//     name: 'Crowd',
//     table: 'crowdv3',
//     type: 'crowdv3',
//     cms: false
//   },
//   {
//     id: 'vehicledetectionv1',
//     name: 'Vehicle Detection',
//     table: 'vehiclerecognitionv1',
//     type: 'vehicledetectionv1',
//     cms: false
//   },
//   {
//     id: 'vehiclerecognitionv1',
//     name: 'Vehicle Recognition',
//     table: 'vehiclerecognitionv1',
//     type: 'vehiclerecognitionv1',
//     cms: false
//   },
//   {
//     id: 'vehiclecrowd',
//     name: 'Vehicle Crowd',
//     table: 'vehiclerecognitionv1',
//     type: 'vehiclecrowd',
//     cms: false
//   },
//   {
//     id: 'signagelivefacev1',
//     name: 'Signagelive',
//     table: 'signagelivefacev1',
//     type: 'signagelivefacev1',
//     cms: true
//   },
//   {
//     id: 'ayudafacev1',
//     name: 'Ayuda',
//     table: 'ayudafacev1',
//     type: 'ayudafacev1',
//     cms: true
//   },
//   {
//     id: 'broadsignfacev1',
//     name: 'Broadsign',
//     table: 'broadsignfacev1',
//     type: 'broadsignfacev1',
//     cms: true
//   },
//   {
//     id: 'brightauthorfacev1',
//     name: 'BrightAuthor',
//     table: 'brightauthorfacev1',
//     type: 'brightauthorfacev1',
//     cms: true
//   },
//   {
//     id: 'broadsignvehiclev1',
//     name: 'Broadsign Vehicle',
//     table: 'broadsignvehiclev1',
//     type: 'broadsignvehiclev1',
//     cms: true
//   },
//   {
//     id: 'scalafacev0',
//     name: 'Scala',
//     table: 'scalafacev0',
//     type: 'scalafacev0',
//     cms: true
//   },
//   {
//     id: 'traffic',
//     name: 'Traffic'
//   },
//   {
//     id: 'audience',
//     name: 'Audience'
//   },
//   {
//     id: 'customsolution',
//     name: 'Custom'
//   }
// ];
const solutionsMeta = [
  {
    id: 'facev2',
    name: 'Audience'
  },
  {
    id: 'crowdv3',
    name: 'Audience'
  },
  {
    id: 'vehicledetectionv1',
    name: 'Traffic'
  },
  {
    id: 'vehiclerecognitionv1',
    name: 'Traffic'
  },
  {
    id: 'vehiclecrowd',
    name: 'Custom'
  },
  {
    id: 'traffic',
    name: 'Traffic'
  },
  {
    id: 'audience',
    name: 'Audience'
  },
  {
    id: 'customsolution',
    name: 'Custom'
  }
];

/**
 * Lookup for some entry in solutionsMeta objects.
 *
 * @param {id of the desired solution} id
 */
const getSolutionData = id => {
  return solutionsMeta.find(solution => solution.id === id.toLowerCase());
};

const availableCMS = {
  signagelivefacev1: {
    name: 'Signagelive',
    table: 'signagelivefacev1',
    playlogTable: 'signagelivev1',
    solutionTable: ['facev2'],
    columns: {
      advertisers: 'playlistName',
      sites: 'NetworkName',
      medias: 'MediaAssetName',
      timestamp: 'EndTime'
    }
  },
  ayudafacev1: {
    name: 'Ayuda',
    table: 'ayudafacev1',
    solutionTable: ['facev2'],
    columns: {
      advertisers: 'advertiserName',
      sites: 'siteCode',
      medias: 'mediaFileName'
    }
  },
  broadsignfacev1: {
    name: 'Broadsign',
    table: 'broadsignfacev1',
    playlogTable: 'broadsignv1',
    solutionTable: ['facev2'],
    columns: {
      advertisers: 'CampaignName',
      sites: 'DisplayUnitName',
      medias: 'AdCopyName',
      timestamp: 'EndTime'
    }
  },
  brightauthorfacev1: {
    name: 'BrightAuthor',
    table: 'brightauthorfacev1',
    playlogTable: 'brightauthorv1',
    solutionTable: ['facev2'],
    columns: {
      advertisers: 'GroupName',
      sites: 'Sites',
      medias: 'FileName',
      timestamp: 'EndTime'
    }
  },
  broadsignvehiclev1: {
    name: 'Broadsign Vehicle',
    table: 'broadsignvehiclev1',
    playlogTable: 'broadsignv1',
    solutionTable: ['vehiclerecognitionv1', 'vehiclecrowd'],
    columns: {
      advertisers: 'CampaignName',
      sites: 'DisplayUnitName',
      medias: 'AdCopyName',
      timestamp: 'EndTime'
    }
  },
  scalafacev0: {
    name: 'Scala',
    table: 'scalafacev0',
    solutionTable: ['facev2'],
    columns: {
      advertisers: 'advertiser',
      sites: 'site',
      medias: 'media'
    }
  }
};

// Emotion aggregation
const emotionConditions = [
  { when: 'HAPPY', then: 'Positive' },
  { when: 'SURPRISED', then: 'Positive' },
  { when: 'NEUTRAL', then: 'Neutral' },
  { when: 'SAD', then: 'Neutral' },
  { when: 'CONFUSED', then: 'Neutral' },
  { when: 'FEAR', then: 'Negative' },
  { when: 'ANGRY', then: 'Negative' },
  { when: 'DISGUST', then: 'Negative' },
  { when: 'happy', then: 'Positive' },
  { when: 'surprised', then: 'Positive' },
  { when: 'neutral', then: 'Neutral' },
  { when: 'sad', then: 'Neutral' },
  { when: 'confused', then: 'Neutral' },
  { when: 'fear', then: 'Negative' },
  { when: 'angry', then: 'Negative' },
  { when: 'disgust', then: 'Negative' }
];

// Age group aggregation
const ageGroupConditions = [
  { when: '', start: 0, end: 20, then: 'Young' },
  { when: '', start: 20, end: 30, then: 'Young Adult' },
  { when: '', start: 30, end: 60, then: 'Adult' },
  { when: '', start: 60, end: 0, then: 'Senior' }
];

// Age group aggregation
const signalPowerConditions = [
  { when: '', start: -100, end: -58, then: 'Far' },
  { when: '', start: -58, end: 0, then: 'Near' }
];

const getReportSolution = compatibleDatasources => {
  //HACK: function made to deal with new types of reports. It should be able to receive (traffic,audience and mobile) in teh future
  const set = new Set(compatibleDatasources.map(str => str.toUpperCase()));

  if (set.size > 2 || (set.has('MOBILE_M') && set.size > 1)) return null;
  if (set.has('TRAFFIC_M') && set.has('AUDIENCE_M')) return 'VEHICLERECOGNITION';
  if (set.has('TRAFFIC_M')) return 'VEHICLERECOGNITION';
  if (set.has('AUDIENCE_M')) return 'FACE';
  if (set.has('MOBILE_M')) return 'MOBILE';

  return null;
};

export {
  solutionsMeta,
  insightsTableHeaders,
  insightsTableData,
  availableCMS,
  emotionConditions,
  ageGroupConditions,
  signalPowerConditions,
  getSolutionData,
  getReportSolution
};
