import config from '../../config';
import API from '../api';

const baseUrl = config.coreApi.baseUrl;

//#region TYPES
/**
 * @typedef User
 * @type {Object}
 * @property {string} email - Email of the user.
 * @property {string} displayName - Display name of the user.
 * @property {string} id - ID of the user.
 * @property {object} preferences - Preferences of the user.
 */
//#endregion

//#region CREATE

/**
 * @description Gets a batch of user info by name
 * @param {string} filter - Filter expression.
 * @param {string[]} names - List of user names.
 * @param {string} orderBy - Order expression.
 * @param {number} pageSize - Max amount of users returned.
 * @param {string} pageToken - Page token (first page is defined as empty string).
 * @returns {{users: [{ displayName: string, email: string, id: string}], nextPageToken: string, totalSize: number}} List of users.
 */
const getUsersBatch = async (emails = [], filter = '', orderBy = 'displayName, id', pageSize = 40, pageToken = '') => {
  await API.refreshTokenWhenNeeded();

  const url = `${baseUrl}/users:batchGet`;
  const queryObject = {
    filter,
    emails,
    orderBy,
    pageSize,
    pageToken
  };

  return fetch(url, {
    headers: API.headers,
    method: 'POST',
    body: JSON.stringify(queryObject)
  }).then(res => API.generateResponse(res));
};

//#endregion
//#region READ
/**
 * @description Gets the current user information.
 * @param {string} accessToken - Access token.
 * @returns {User} Current user.
 */
const getCurrentUser = async accessToken => {
  await API.refreshTokenWhenNeeded();

  const url = `${baseUrl}/currentuser`;
  const token = API.isImpersonating ? API.originalToken : accessToken;
  return fetch(url, {
    headers: {
      ...API.headers,
      Authorization: `Bearer ${token}`
    },
    method: 'GET'
  }).then(API.generateResponse);
};

/**
 * @description Gets the custom widgets for the current user.
 * @param {string} accessToken - Access token.
 * @returns {{customWidgets: object}} custom widgets.
 */
const getUserCustomWidgets = async accessToken => {
  await API.refreshTokenWhenNeeded();
  const token = API.isImpersonating ? API.originalToken : accessToken;

  const url = `${baseUrl}/currentuser:getCustomWidgets`;
  return fetch(url, {
    headers: {
      ...API.headers,
      Authorization: `Bearer ${token}`
    },
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

/**
 * @description Check is a feature flag is active for the current user.
 * @param {string} featureFlag - Feature flag to check.
 * @returns {{ active: boolean }} active feature flags.
 */
const getActiveFeatureFlag = async featureFlag => {
  await API.refreshTokenWhenNeeded();

  const url = `${baseUrl}/currentuser:isFeatureFlagActive?featureFlag=${featureFlag}`;

  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};
//#endregion

//#region UPDATE

/**
 * @description Updates the current user.
 * @param {string} accessToken - Access token for authentication.
 * @param {User} user - User object containing updated fields.
 * @param {string[]} fields - List of user fields to update.
 * @returns {User} The updated user information.
 */
const updateCurrentUser = async (user, fields) => {
  await API.refreshTokenWhenNeeded();

  const paths = `updateMask=${fields.join(',')}`;
  const body = {};
  const url = `${baseUrl}/currentuser?${paths}`;

  fields.forEach(field => {
    body[field] = user[field];
  });

  return fetch(url, {
    method: 'PATCH',
    headers: API.headers,
    body: JSON.stringify(body)
  }).then(res => API.generateResponse(res));
};

/**
 * @description Updates the custom widgets for the current user.
 * @param {string} accessToken - Access token for authentication.
 * @param {object} customWidgets - Object containing custom widgets for the user.
 * @returns {object} The updated custom widgets for the user.
 */
const updateCustomWidgets = async (accessToken, customWidgets) => {
  await API.refreshTokenWhenNeeded();
  const token = API.isImpersonating ? API.originalToken : accessToken;

  const url = `${baseUrl}/currentuser:updateCustomWidgets`;

  return fetch(url, {
    method: 'PATCH',
    headers: {
      ...API.headers,
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ customWidgets: customWidgets })
  }).then(res => API.generateResponse(res));
};
//#endregion

const impersonateUser = async (userId, token) => {
  if (!token) {
    await API.refreshTokenWhenNeeded();
  }

  const url = `${baseUrl}/currentuser:impersonate`;
  // Prevent refreshToken
  API.isImpersonating = true;
  API.userId = userId;
  API.originalToken = token || API.access_token;

  return fetch(url, {
    method: 'POST',
    headers: {
      ...API.headers,
      Authorization: token ? `Bearer ${token}` : API.headers.Authorization
    },
    body: JSON.stringify({
      targetName: `users/${userId}`
    })
  }).then(API.generateResponse);
};

export {
  getUsersBatch,
  getCurrentUser,
  getUserCustomWidgets,
  getActiveFeatureFlag,
  updateCurrentUser,
  updateCustomWidgets,
  impersonateUser
};
