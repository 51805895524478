import API from '../../services/api';
import dateTimeUtils from '../../utils/calendar';
import { getReportSolution } from '../../utils/solutions';

const generateCommonFieldsReportObject = reportObject => {
  const { project, report } = reportObject;

  if (!project || !report) return {};

  return {
    advertisers: report.advertisers,
    devices: [],
    email: report.email,
    endTime: '',
    filterByTime: report.filterByTime,
    filterByWeekday: report.filterByWeekday,
    format: report.format,
    medias: report.medias,
    speedUnit: project.preferences?.speedUnit || '',
    startTime: report.startDate,
    timezone: report.timezone,
    solution: report.solution
  };
};

const generateSingleReportObject = reportObject => {
  const { project, report } = reportObject;
  if (!project || !report) return {};

  const baseReportObject = generateCommonFieldsReportObject(reportObject);

  const companyId = project.companyId;

  // Populate Devices list
  const devices = report?.devices?.map(deviceId => ({
    deviceId: deviceId,
    deviceName: `companies/${companyId}/devices/${deviceId}}`
  }));

  return {
    ...baseReportObject,
    devices,
    endTime: report.endDate,
    epoch: String(report.epoch.id).toUpperCase(),
    fields: report.fields // CSV columns to use on the Report
  };
};

const generateRecurringReportObject = reportObject => {
  const { report } = reportObject;

  if (!report) return {};

  const baseReportObject = generateCommonFieldsReportObject(reportObject);
  const devices = report?.devices?.map(deviceId => deviceId);
  const endDate = dateTimeUtils.addTime(99, 'years');

  return {
    ...baseReportObject,
    daysToSend: report.daysToSend,
    devices,
    endTime: endDate,
    frequency: report.frequency,
    interval: String(report.interval.id).toUpperCase()
  };
};

export const submit = async (report, project, selectedTemplate) => {
  const reportObject = {
    report: { ...report, solution: getReportSolution(selectedTemplate.compatibleDatasources) },
    project
  };

  if (report?.frequency === 'single') {
    const data = generateSingleReportObject(reportObject);
    return API.createSingleReport(project.companyId, project.id, report.solution.table, data);
  } else {
    const data = generateRecurringReportObject(reportObject);
    return API.createRecurrentReport(project.companyId, project.id, report.solution.table, data);
  }
};
