import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import types from '../../redux/types';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import CustomInput from '../customInput';
import CustomButton from '../customButton';

import utils from '../../utils';
import { submit } from './common';

import icons from '../../assets/icons';
import './styles.scss';
import calendar from '../../utils/calendar';

const QuickReport = ({ report, project, classes, onClose, isOpen, selectedTemplate }) => {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (report) {
      setEmail(report.email);
      setStartDate(calendar.getDateTime(report.startDate, 'date'));
      setEndDate(calendar.getDateTime(report.endDate, 'date'));
    }
  }, [report]);

  useEffect(() => {
    const validations = {
      email: [
        { key: 'required', value: true },
        { key: 'email', value: true }
      ]
    };
    setErrors({
      'email-input': utils.fieldValidation(email, validations['email']).join(', ')
    });
  }, [email]);

  const sendReport = async () => {
    if (!utils.hasErrorsCheck(errors)) {
      try {
        setLoading(true);
        await submit({ ...report, email }, project, selectedTemplate);
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert('Report created successfully', 'success')
        });
      } catch (e) {
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert(e.message, 'error')
        });
      } finally {
        setLoading(false);
        onClose();
      }
    }
  };

  const isRecurringReport = () => report.frequency && report.frequency !== 'single';

  return (
    <Modal isOpen={isOpen} fade={false} toggle={onClose} className={classes} centered>
      <ModalHeader toggle={onClose} />
      <ModalBody>
        <img src={isRecurringReport() ? icons.reportRecurring : icons.reportEmail} alt="Send report to e-mail"></img>
        <h2 className={isRecurringReport() ? 'recurring-report' : ''}>
          {`Your ${isRecurringReport() ? 'recurring' : ''} CSV report is ready to be sent!`}
        </h2>
        <p>We just need an e-mail now.</p>
        <div className="quick-report-info">
          <div>
            <img src={icons.timezone} alt=""></img>
            <span>{report.timezone}</span>
          </div>
          <div>
            <img src={icons.calendar} alt=""></img>
            <span>{`${startDate} - ${endDate}`}</span>
          </div>
          <div>
            <img src={icons.deviceOutlineDark} alt=""></img>
            <span>{`${report.devices.length} device${report.devices.length !== 1 ? 's' : ''} included`}</span>
          </div>
          {isRecurringReport() && (
            <div>
              <img src={icons.recurrency} alt=""></img>
              <span>{utils.generateRecurringReportSummary(report)}</span>
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="email-input">E-mail</label>
          <CustomInput
            id="email-input"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            errorMessage={errors['email-input']}
            isEditable
          />
        </div>
        <CustomButton
          classes={'btn-primary'}
          handleClick={sendReport}
          loading={loading}
          disabled={utils.hasErrorsCheck(errors)}
          title={`Send Report ${isRecurringReport() ? 'Recurrently' : ''}`}
        />
      </ModalBody>
    </Modal>
  );
};

export default QuickReport;
