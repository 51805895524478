import { call, put, select } from 'redux-saga/effects';
import types from '../redux/types';
import API from '../services/api';
import { clearProjectsData, normalizeProject } from '../utils/project';
import utils from '../utils';
import { fetchCompanies } from './company';

// Fetch Presets aka Solutions Config from API
export function* fetchSolutionsConfig(projects) {
  const allSolutions = [];
  let solutionsConfig = {};

  for (const project of projects) {
    for (const solution of project.enabledSolutions) {
      if (!allSolutions.includes(solution)) {
        allSolutions.push(solution);
      }
    }
  }

  const solutionsConfigsResponse = yield Promise.all(
    allSolutions.map(solutionType => API.getSolutionsConfig(solutionType))
  );

  solutionsConfigsResponse.forEach((solutionConfigResponse, index) => {
    solutionsConfig[allSolutions[index]] = solutionConfigResponse.solutionConfigs;
  });

  return solutionsConfig;
}

// Return default Project from user preferences or first project found
export function* getDefaultProject(projects) {
  if (!projects || projects.length === 0) {
    return null;
  }
  // Get user preference to load default project
  const user = yield select(state => state.userReducer.user);
  const company = yield select(state => state.companyReducer.selectedCompany);
  let selectedProject = projects[0];

  // Updated selected device based on user preferences
  if (user.preferences && user.preferences.defaultProjectId) {
    // Get default project from API
    let defaultProject = null;
    try {
      defaultProject = yield call(API.getSiteGroup, company.id, user.preferences.defaultProjectId);
      selectedProject = defaultProject ? defaultProject : selectedProject;
    } catch (e) {
      console.error('Could not fetch default sitegroup', e);
    }
  }

  return normalizeProject(selectedProject);
}

export function* fetchProjects() {
  // Fetch project info from API
  const company = yield select(state => state.companyReducer.selectedCompany);
  const user = yield select(state => state.userReducer.user);

  let loadedProjects = [];

  try {
    let project = yield call(API.getSiteGroup, company.id, user.preferences.defaultProjectId);
    loadedProjects = [project];
  } catch (error) {
    try {
      let { projects } = yield call(API.getSiteGroups, 200);
      loadedProjects = projects;
      if (!company) {
        const id = projects[0].companyId;
        let localCompany = yield call(API.getCompany, id);
        yield put({ type: types.LOAD_COMPANIES, payload: [{ id }] });
        yield put({ type: types.SELECT_COMPANY, payload: localCompany });
      }
    } catch (e) {
      console.error('Could not fetch site groups', e);
      loadedProjects = [];
    }
  }

  // Search through projects the one with more devices to select
  const normalizedProjects = loadedProjects.map(project => {
    return normalizeProject(project);
  });

  return normalizedProjects;
}

export function* loadProjects() {
  try {
    yield call(fetchCompanies);
    const projects = yield call(fetchProjects);
    const selectedProject = yield call(getDefaultProject, projects);
    const company = yield call(API.getCompany, selectedProject.companyId);
    // Get solution Presets from API
    const solutionsConfig = yield call(fetchSolutionsConfig, projects);

    // Set Projects on Redux
    yield put({ type: types.LOAD_PROJECTS, payload: projects });
    // Set Selected Project on Redux
    yield put({ type: types.SELECT_PROJECT, payload: selectedProject });
    yield put({ type: types.SELECT_COMPANY, payload: company });
    // Set Presets on Redux
    yield put({ type: types.LOAD_SOLUTIONS_CONFIG, payload: solutionsConfig });
  } catch (e) {
    console.error(e);
    yield put({
      type: types.SET_ALERT,
      payload: utils.generateErrorAlert("Couldn't fetch Site groups. Try to refresh this page.")
    });
  }
}

export function* updateUserDefaultProject({ payload: project }) {
  if (project) {
    try {
      const user = yield select(state => state.userReducer.user);
      const userPreferences = { ...user.preferences, defaultProjectId: project.id };
      const customWidgets = userPreferences.customWidgets;
      delete userPreferences.customWidgets;
      yield call(API.updateCurrentUser, { preferences: userPreferences }, ['preferences']);
      yield put({ type: types.UPDATE_USER, payload: { ...user, preferences: { ...userPreferences, customWidgets } } });
    } catch (e) {
      console.error(e);
      // Clear project data from localStorage to prevent errors when changing env (dev/prod)
      clearProjectsData();
    }
  }
}

export function* fetchTables() {
  const selectedProject = yield select(state => state.projectReducer.selectedProject);
  const selectedCompany = yield select(state => state.companyReducer.selectedCompany);

  if (!selectedProject || !selectedCompany) return;

  const projectId = selectedProject.id;
  const companyId = selectedCompany.id;

  try {
    const tables = yield call(API.getTables, companyId, projectId);
    selectedProject.tables = tables.tables;
    yield put({ type: types.SET_PROJECT_TABLES, payload: selectedProject });
  } catch (e) {
    yield put({ type: types.SET_PROJECT_TABLES, payload: null });
    yield put({
      type: types.SET_ALERT,
      payload: utils.generateErrorAlert("Couldn't load tables. Try again or refresh this page.")
    });
  }
}
